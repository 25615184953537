import React from 'react'
import Layout from '../../components/layout'
import { GroupingCreator } from '../../components/ChallengeCreators'

const GroupingCreatorPage = ({ learningGoalId }) => {
  return <Layout>
    <GroupingCreator learningGoalId={learningGoalId} />
  </Layout>
}

export default GroupingCreatorPage
